import React from "react";
import Popover from "@cloudscape-design/components/popover";
import Link from "@cloudscape-design/components/link";
import Box from "@cloudscape-design/components/box";

export default function LetsConnect() {
    return (
        <Popover
            dismissButton={false}
            position="bottom"
            size="medium"
            header="Let's Collaborate"
            triggerType="custom"
            content={
                <Box>
                    I'm Jeremy Sullivan. Come find me on <Link href="https://www.linkedin.com/in/jeremysullivan/">LinkedIn</Link> or drop me an <Link href="mailto:jersu11@gmail.com">email</Link> to chat about what you see here. Looking forward to connecting with you!
                </Box>
            }
        >
            <Link variant="info">Info</Link>
        </Popover>
    );
}