import React, { useState } from "react";
import Flashbar from "@cloudscape-design/components/flashbar";
import Link from "@cloudscape-design/components/link";

export default function LoginStatus({ status, email }) {
  // State to manage Flashbar items
  const [flashbarItems, setFlashbarItems] = useState([]);

  // Initial Flashbar properties, moved inside useEffect to react on status or email changes
  React.useEffect(() => {
    const initialFlashbarProps = {
      loading: false,
      type: 'info',
      content: 'checking for updates ...', 
      onDismiss: () => setFlashbarItems([]), // Update to modify the state
      dismissible: true,
      id: "message_1"
    };

    // Function to configure properties based on status
    const configureFlashbarProps = (status) => {
      // Base configuration
      let props = { ...initialFlashbarProps };

      switch (status) {
        case 'loading':
          props = { ...props, loading: true, content: 'Checking email address and loading content ...' };
          break;
        case 'allowed':
          props = { ...props, type: 'success', content: `Welcome, ${email}` };
          break;
        case 'denied':
          props = {
            ...props, type: 'warning', content: (
              <>
                Sorry, your email address was not found. Please{" "}
                <Link href="mailto:jersu11@gmail.com">
                  connect with me
                </Link>
                {" "}if you would like to see these demos.
              </>
            )
          };
          break;
        default:
          break; // Optionally handle default case
      }

      return props;
    };

    // Update the Flashbar items
    if (email !== "") {
      setFlashbarItems([configureFlashbarProps(status)]);
    } else {
      setFlashbarItems([]);
    }

    // Set the flashbar items based on the current status
  }, [status, email]); // Depend on status and email so it updates when they change

  return (
    <Flashbar items={flashbarItems} />
  );
}
