export const makeApiRequest = async (email) => {
    const emailReverse = email.split('').reverse().join('');
    const base64Credentials = btoa(`${email}:${emailReverse}`);

    try {
        const response = await fetch('https://api.jersu.us/posts', {
            method: 'GET',
            headers: {
                'Authorization': `Basic ${base64Credentials}`,
                'Content-Type': 'application/json'
            },
        });

        if (response.ok) {
            return response.json();
        } else if (response.status === 403) {
            throw new Error('Access denied. You are not authorized to access this resource.');
        } else {
            throw new Error('Something went wrong');
        }

    } catch (error) {
        throw error;
    }
};
