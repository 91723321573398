import React, { useState } from 'react';
import DemoCards from './Cards';
import VideoProject from './VideoProject';

export default function DemoContent({ content }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  //console.log(JSON.stringify(content, null, 2));

  // Extracting the current post based on the selected index
  const currentPost = content[currentIndex];

  const simplifiedContentList = content.map(item => ({
    id: item.content_id,
    title: item.title,
    description: item.description,
  }));

  // Handler for changing the current post, to be passed to ListOfAvailableContent
  const handleSubmit = (detail) => {
    // console.log(detail);
    // Extract the selected item's ID from the detail object
    const selectedItemId = detail.selectedItems[0].id;

    // Find the index of the item in the original content array
    const selectedIndex = content.findIndex(item => item.content_id === selectedItemId);

    // Check if the item was found
    if (selectedIndex !== -1) {
        // Update the currentIndex state to reflect the selection
        setCurrentIndex(selectedIndex);
    } else {
        console.log('Selected item not found in the content list');
    }
};


  return (
    <>
      <DemoCards contentList={simplifiedContentList} handleChange={handleSubmit} />
      {currentPost && ( 
        <VideoProject
          title={currentPost.title}
          markdown={currentPost.markdown}
          videoSrc={currentPost.s3_video}
        />
      )}
    </>
  );
}
