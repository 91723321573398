import React from "react";
import Form from "@cloudscape-design/components/form";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Modal from "@cloudscape-design/components/modal";

export default function EmailInput({ visible, onClose, onEmail }) {
    const [value, setValue] = React.useState("");

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission
        onEmail(value); // Call the onSave function passed from the parent, with the current email value
    };

    return (
        <Modal
            onDismiss={onClose}
            visible={visible}
            header="address check"
            size="small"
        >
            {/* Use the onSubmit event handler on the form element */}
            <form onSubmit={handleSubmit}>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            {/* Change type to "submit" to ensure form submission */}
                            <Button type="submit" variant="primary">Submit</Button>
                        </SpaceBetween>
                    }
                >
                    <FormField
                        description="Please enter your email address to contnue">
                        <Input
                            onChange={({ detail }) => setValue(detail.value)}
                            name="email"
                            inputMode="email"
                            type="email"
                            value={value}
                        />

                    </FormField>
                </Form>
            </form>
        </Modal>
    );
}
