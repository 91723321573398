import React from "react";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Box from "@cloudscape-design/components/box";
import ReactMarkdown from 'react-markdown';

export default function VideoProject({ videoSrc, vidTitle, markdown }) {

    return (
        <Container
            media={{
                content: (
                    <video controls key={videoSrc}>
                        <source src={videoSrc} type="video/mp4" />
                        Your browser does not support the video tag
                    </video>
                )
            }}
        >

            <SpaceBetween direction="vertical" size="s">
                <SpaceBetween direction="vertical" size="xxs">
                    <Box variant="h2">
                        {vidTitle}
                    </Box>
                </SpaceBetween>
                <ReactMarkdown>{markdown}</ReactMarkdown>
            </SpaceBetween>
        </Container>
    );
}