import React, { useState } from "react";
import Cards from "@cloudscape-design/components/cards";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Link from "@cloudscape-design/components/link";

export default function DemoCards({ contentList, handleChange }) {
    const [selectedItems, setSelectedItems] = useState([0]);

    // give a real default value when no item is selected
    if (selectedItems.every(item => item === 0)) {
        setSelectedItems([{'id': contentList[0].id}]);
    }

    return (
        <Cards
            onSelectionChange={({ detail }) => {
                setSelectedItems(detail?.selectedItems ?? [])
                handleChange(detail)
            }}
            selectedItems={selectedItems}
            cardDefinition={{
                header: item => (
                    <Link fontSize="heading-s">
                        {item.title}
                    </Link>
                ),
                sections: [
                    {
                        id: "description",
                        content: item => item.description
                    }
                ]
            }}
            cardsPerRow={[{
                cards: 1
              }, {
                minWidth: 1200,
                cards: 2
              }, {
                minWidth: 1800,
                cards: 3
              }]}
            entireCardClickable
            items={contentList}
            loadingText="Loading resources"
            selectionType="single"
            trackBy="id"
            visibleSections={["description"]}
            empty={
                <Box
                    margin={{ vertical: "xs" }}
                    textAlign="center"
                    color="inherit"
                >
                    <SpaceBetween size="m">
                        <b>No resources</b>
                    </SpaceBetween>
                </Box>
            }
        />
    );
}