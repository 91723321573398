import React, { useState } from 'react';
import {
    AppLayout,
    Box,
    Container,
    ContentLayout,
    Header,
    Link,
    SpaceBetween,
} from "@cloudscape-design/components";

import DemoContent from './DemoContent';
import EmailInput from './EmailInput'; // Adjust the import path as needed
import LetsConnect from './LetsConnect';
import LoginStatus from './LoginStatus'; // Adjust the import path as needed
import { makeApiRequest } from './api';


export default function App() {
    const [email, setEmail] = useState(''); // State to store the email
    const [loginStatus, setLoginStatus] = useState('loading');
    const [loginVisible, setLoginVisible] = useState(true);
    const [content, setContent] = useState({});


    const onEmail = async (emailFromInput) => {
        console.log(`Validating email: ${emailFromInput}`);

        setLoginVisible(false);
        setEmail(emailFromInput);

        try {
            const apiContent = await makeApiRequest(emailFromInput);
            setContent(apiContent);
            setLoginStatus('allowed');
        } catch (error) {
            console.error('Error making API request:', error);
            setLoginStatus('denied');
        }
    };

    const resetEmail = () => {
        setEmail('');
        setLoginStatus('loading');
        setLoginVisible(true);
        setContent('');
    }

    return (
        <AppLayout
            toolsHide={true}
            navigationHide={true}
            notifications={<LoginStatus status={loginStatus} email={email} />}
            content={
                <ContentLayout
                    header={
                        <Header variant="h1" description="Demonstration Videos, Explanation and Resources" info={<LetsConnect />}>
                            Leveraging AI in the DevSecOps Lifecycle
                        </Header>
                    }
                >

                    <SpaceBetween size="l">
                        <Container>
                            {/* conditionally render EmailInput if email hasn't yet been validated */}
                            {(!email && loginVisible) && <EmailInput visible={loginVisible} onClose={() => setLoginVisible(false)} onEmail={onEmail} />}

                            {/* good email, got content, let's show it! */}
                            {((loginStatus === 'allowed') && content) && <DemoContent content={content} />}
                        </Container>

                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Link onFollow={() => resetEmail()}>
                                    reset email
                                </Link>
                            </SpaceBetween>
                        </Box>

                    </SpaceBetween>
                </ContentLayout>
            }
        />
    );
}